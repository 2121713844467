.accordion {
    &:not(:last-child) {
        border-bottom: 1px solid $color-grey;
    }

    &__group {
        margin-bottom: 30px;
    }

    &__header {
        appearance: none;
        background-color: $color-white;
        background-image: url(/assets/images/icons/expand-more.svg);
        background-repeat: no-repeat;
        background-position: center right 4px;
        color: $color-main;
        font-size: 15px;
        font-weight: 700;
        border: 0;
        padding: 12px 1.75rem 12px 16px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover,
        &[aria-expanded=true] {
            background-color: $color-blue-40;
        }

        &[aria-expanded=true] {
            background-image: url(/assets/images/icons/expand-less.svg);
        }
    }

    &:nth-child(2n - 1) {
        .accordion__header {
            background-color: $color-blue-0;

            &:hover,
            &[aria-expanded=true] {
                background-color: $color-blue-40;
            }
        }
    }

    &__body {
        padding: 0;
        margin: 0;

        li {
            padding: 12px 16px;
            display: flex;
            justify-content: space-between;
            font-size: 15px;

            &:nth-child(2n -1) {
                background-color: $color-blue-0;
            }

            strong {
                font-weight: 700;
            }
        }
    }
}

.accordion-tree {
    flex: 1;
    z-index: 1;
    margin-bottom: 15px;

    .accordion-item {
        position: relative;
    }

    .accordion-header {
        min-width: 320px;
        appearance: none;
        border: 0;
        margin-bottom: 10px;
        border-radius: 2px;
        position: relative;
        color: $color-main;

        .accordion-button {
            display: flex;
        }

        &--white {
            .accordion-button {
                background-color: $color-white;

                .accordion-title {
                    strong {
                        color: $color-blue-80;
                    }
                }
            }
        }

        &.is-parent,
        &.level-0 {
            color: $color-white;

            .accordion-title {
                strong {
                    color: inherit;
                }
            }

            .accordion-button {
                background-color: $color-blue-60 !important;
            }

            .accordion-action-button {
                background-color: $color-blue-80;
            }
        }

        &.has-action-button {
            .accordion-button {
                &:hover {
                    background-color: $color-blue-20;

                    .accordion-action-button {
                        opacity: 1;
                    }
                }
            }
        }

        &.is-current,
        &.level-1 {
            color: $color-white;

            .accordion-title {
                strong {
                    color: inherit;
                }
            }

            .accordion-button {
                background-color: $color-blue-80 !important;
            }

            .accordion-action-button {
                background-color: $color-blue-60;
            }
        }

        &.is-child {
            color: $color-main;

            .accordion-title {
                strong {
                    color: inherit;
                }
            }

            .accordion-button {
                background-color: $color-blue-20 !important;
            }

            .accordion-action-button {
                background-color: $color-main;
            }
        }

        @media (min-width: 992px) {
            min-width: 500px;
        }

        &.has-children {
            .accordion-button {
                cursor: pointer;

                &:after {
                    @include icomoon;
                    content: '\e911';
                    font-size: 24px;
                    position: absolute;
                    right: 14px;
                    top: 10px;
                    color: inherit;
                    pointer-events: none;
                }

                &.collapsed {
                    &:after {
                        content: '\e910';
                    }
                }
            }
        }

        &:not(.has-children) {
            .accordion-button {
                cursor: unset;
            }
        }
    }

    .accordion-delete {
        margin-left: auto;
        color: inherit;
    }

    .accordion-button {
        position: relative;
        min-height: 44px;
        width: 100%;
        appearance: none;
        border: none;
        text-align: left;
        padding: 0 38px 0 14px;
        background-color: $color-blue-20;
        color: inherit;

        .dropdown,
        .dropdown .btn-icon {
            color: inherit;
        }
    }

    .accordion-action-button {
        margin-right: 15px;
        margin-left: auto;
        appearance: none;
        font-size: 14px;
        line-height: 1;
        color: $color-white;
        border: 0;
        padding: 7px 13px;
        background-color: $color-blue-80;
        border-radius: 15px;
        opacity: 0;
        transition: .2s linear;
    }

    .accordion-title {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 15px;

        strong,
        span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        strong {
            font-size: 15px;
        }

        span {
            font-size: 11px;
        }

        @media (min-width: 992px) {
            flex-direction: row;
            align-items: center;

            strong,
            span {
                font-size: 16px;
            }

            span {
                margin-left: 10px;
            }
        }
    }

    .accordion-item .accordion-item {
        padding-left: 30px;

        .accordion-header {
            &:before {
                content: '';
                width: 15px;
                height: 54px;
                position: absolute;
                bottom: 21px;
                left: -15px;
                border-bottom: 1px solid #707070;
                border-left: 1px solid #707070;
                z-index: -1;
            }
        }
    }
}