.form-group {
    position: relative;
    text-align: left;

    &--inline,
    &--list {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        label,
        .label {
            width: 100%;
        }

        .input-group {
            flex: 1;
        }

        @media (min-width: 992px) {

            label,
            .label {
                width: 200px;
            }
        }
    }

    &--checkbox {

        label,
        .label {
            width: 200px;
        }

        input[type=checkbox] {
            top: -7px;
        }
    }

    &--inline-simple {
        display: flex;
        align-items: flex-end;

        label {
            font-weight: normal;

            @media (max-width: 991px) {
                width: 200px
            }
        }

        .input-group {
            width: 250px;

            @media (max-width: 1199px) {
                width: 170px;
            }

            @media (max-width: 991px) {
                width: 300px;
            }
        }

        @media (min-width: 992px) {
            &:not(:first-child) {
                margin-left: 16px;
            }
        }

    }

    &--inline-modal {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        label,
        .label {
            width: fit-content;
        }

        .input-group {
            flex: 1;
        }
    }

    &--radio {
        .input-group {
            @media (max-width: 991px) {
                flex-wrap: wrap !important;
            }
        }
    }

    &--list {
        align-items: flex-start;
    }

    .form-input-select {
        display: flex;
        flex: 1;
        align-items: flex-end;
    }

    .form-input-select__footer {
        width: 100%;
        padding: 5px 12px;
        background-color: $color-white;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        text-align: center;
        color: $color-main;
        opacity: 0.8;
    }

    .form-list {
        font-weight: 700;
        flex: 1;
        margin-bottom: 6px;
    }

    .form-prefix {
        appearance: none;
        background-color: $color-blue-40;
        width: 42px;
        height: 42px;
        margin-right: 10px;
        border-radius: 3px;
        text-align: center;
        padding: .375rem;
        font-size: 16px;
        line-height: 22px;
        color: $color-blue-100;
        border: 2px solid transparent;

        &.is-invalid {
            border-color: #dc3545;
            color: #dc3545;
        }

        &.disabled,
        &[disabled] {
            background-color: $color-body;
        }
    }

    .form-preselect {
        appearance: none;
        border: 0;
        border-bottom: 2px solid $color-blue-80;
        background-color: $color-white;
        height: 42px;
        width: 40px;
        padding: 4px 10px 0 0;
        color: $color-main;
        font-size: 14px;
        font-weight: 400;
        background-image: url(/assets/images/icons/expand-more.svg);
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: top 14px right 0;
        padding-right: 18px;
        margin-right: 10px;

        &:focus {
            background-image: url(/assets/images/icons/expand-less.svg);
        }

        &.is-invalid {
            border-color: #dc3545;
            color: #dc3545;
        }
    }

    .input-group {
        &:after {
            content: "";
            width: 100%;
            margin: 0 2px;
            height: 2px;
            background: $color-blue-80;
        }

        &.date {
            &:before {
                @include icomoon;
                content: "\e915";
                color: $color-blue-80;
                position: absolute;
                z-index: 1;
                top: 50% !important;
                right: 12px;
                pointer-events: none;
                transform: translateY(-50%);
            }

            &>.form-control {
                padding-right: 2rem;
            }
        }

        &.error:after {
            background: #dc3545;
        }

        &.unstyled {
            display: flex;
            flex-wrap: nowrap;

            &:after {
                content: none;
            }
        }

        .form-control {
            background-color: $color-white;
            border-radius: 3px;
            border-color: transparent;
            height: 40px;
            font-size: 16px;
            line-height: 18px;
            color: $color-main;
            appearance: none;

            &__textarea {
                resize: none;
                height: 120px;
                border-color: $color-blue-80;

                &.vertical-resize {
                    resize: vertical;
                    min-height: 120px;
                    max-height: 600px;
                }
            }

            &.is-invalid {
                border-color: $color-error;
            }

            &[disabled],
            &.disabled {
                background-color: $color-body;
                color: transparentize($color-main, 0.4);

                &+.input-group-append {
                    background-color: $color-body;
                }
            }
        }

        select {
            background-image: url(/assets/images/icons/expand-more.svg);
            background-repeat: no-repeat;
            background-position: center right 4px;
            padding-right: 1.75em;

            &:focus {
                background-image: url(/assets/images/icons/expand-less.svg);
            }
        }

        .input-group-append {
            border-radius: 0 3px 3px 0;
            background: $color-white;

            button {
                background: transparent;
                border-radius: 3px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                color: $color-blue-80;

                i {
                    pointer-events: none;
                }

                .icon-info {
                    font-size: 20px;
                }

                .icon-eye,
                .icon-eye-close {
                    font-size: 24px;
                }
            }
        }

        &--small {

            label,
            .label {
                width: unset;
            }

            .form-control {
                height: 30px;
            }
        }
    }

    .invalid-feedback {
        display: block;
        font-size: 14px;

        &:before {
            content: '\00D7';
            color: $color-white;
            background-color: $color-error;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 700;
            width: 14px;
            height: 14px;
            line-height: 14px;
            text-align: center;
            display: inline-block;
            margin-right: 6px;
        }

        &.light {
            color: $color-white;
        }
    }

    &--white {

        label,
        .label {
            color: $color-white;
        }

        .form-control,
        .input-group .form-control {
            border-color: $color-white;
        }
    }

    &--transparent {

        label,
        .label {
            color: $color-white;
        }

        .input-group:after {
            background: $color-white;
        }

        .form-control,
        .input-group .form-control {
            color: $color-white;
            background-color: transparent;
            border-color: transparent;
        }
    }

    .icon-info {
        margin-left: 5px;
        color: #0C7FB8;

        &.green {
            color: $color-green;
            font-size: 24px;
        }

        &.light-background {
            position: relative;

            &::after {
                content: '';
                background-color: $color-white;
                width: 90%;
                height: 90%;
                position: absolute;
                top: 5%;
                left: 5%;
                border-radius: 50%;
            }

            &::before {
                position: relative;
                z-index: 1;
            }
        }
    }
}

.additional-action {
    appearance: none;
    border: 0;
    background: unset;
    padding: 0;
    color: $color-blue-80;
    text-decoration: underline;
    margin-left: 0;

    @media (min-width: 992px) {
        margin-left: 115px;
    }
}


label,
.label {
    display: flex;

    font-family: $font-officina;
    font-size: 16px;
    margin: 0 15px 6px 0;
    line-height: 18px;
    font-weight: 700;
    color: $color-blue-80;

    i {
        margin-left: 10px;
        flex-shrink: 0;
        font-size: 18px;
    }

    &.error {
        color: #dc3545;
    }
}

.form-info-line {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $color-blue-80;
    font-style: italic;
}

.checkbox {
    appearance: none;
    background-color: $color-white;
    border: 1px solid $color-blue-80;
    color: $color-blue-80;
    border-radius: 1px;
    width: 16px;
    height: 16px;
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:after {
        @include icomoon;
        content: "\e90f";
        font-weight: 700;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
    }

    &:checked {
        background-color: $color-blue-80;
        color: $color-white;

        &::after {
            opacity: 1;
        }
    }

    &--white {
        border-color: $color-white;
        background-color: transparent;
        color: $color-blue-80;

        &:checked {
            background-color: $color-white;
            color: $color-blue-80;
        }
    }

    &--outline-white {
        background-color: red !important;
    }

    &__wrp {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        label {
            width: auto;
        }

        .checkbox {
            margin: 0 15px 6px 0;
        }
    }

    &[disabled] {
        opacity: .5;
        cursor: unset;
    }
}

.radio {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid $color-blue-80;
    background-color: $color-white;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;

    &:after {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        background-color: transparent;
        border-radius: 50%;
    }

    &:checked {
        &:after {
            background-color: $color-blue-80;
        }
    }

    &[disabled] {
        opacity: .5;
        cursor: unset;
    }
}

.file-upload {
    color: $color-blue-100;
    margin: 20px auto 40px;

    &--white {
        color: $color-white;

        .file-upload__dropzone {
            background-color: transparent;
        }

        i {
            color: $color-white;
        }
    }

    &--dark {
        color: $color-blue-80;

        .file-upload__dropzone {
            background-color: $color-blue-0;
        }

        i {
            color: $color-blue-80;
        }
    }

    &__dropzone {
        width: 100%;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px dashed;
        position: relative;
        font-size: 12px;
        font-weight: 700;
        border-radius: 4px;
        transition: 0.15s all;

        &:hover,
        &:focus-within {
            background-color: transparentize($color: $color-white, $amount: .9);
        }

        &.disabled {
            background-color: $color-body;

            * {
                filter: grayscale(1);
            }

            .file-upload__input {
                cursor: inherit;
            }
        }
    }

    &__input {
        position: absolute;
        appearance: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    &__title {
        font-size: 16px;
        color: inherit;
    }

    &__icon {
        width: 110px;
        margin: 20px auto 30px;
    }

    &__btn {
        margin: 30px auto 20px;
    }

    &__helper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0 0.5em;

        @media (min-width: 992px) {
            flex-direction: row;
        }
    }

    &__file {
        text-align: left;
        margin: 15px 0 0;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        justify-content: flex-start;

        span {
            flex-shrink: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        button {
            appearance: none;
            border: 0;
            background-color: transparent;
            padding: 0;
            line-height: 1;
            flex-shrink: 0;
            margin-left: 16px;
        }
    }

    &__error {
        display: block;
        font-size: 14px;
        text-align: left;
        margin: 15px 0 0;

        &:before {
            content: '\00D7';
            color: $color-white;
            background-color: $color-error;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 700;
            width: 14px;
            height: 14px;
            line-height: 14px;
            text-align: center;
            display: inline-block;
            margin-right: 6px;
        }
    }
}

.form-title {
    font-size: 20px;
    line-height: 23px;
    font-weight: 400;
    margin: 40px 0 20px;
    color: $color-blue-80;
}

.form-check {
    height: 40px;

    .form-check-input {
        position: relative;
        margin-right: 12px;
        top: 4px;
    }

    .form-check-label {
        width: auto !important;
        font-size: 16px;
        line-height: 24px;
        color: $color-main;
        font-weight: 400;
        position: relative;
        top: 4px;
    }
}

.form-group-flex {
    @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;

        .form-group {
            &:not(:last-child) {
                margin-right: 20px;
            }

            label {
                width: auto !important;
            }

            .input-group {
                width: 140px;
                flex: unset;

                &.date {
                    width: 240px;
                }
            }
        }
    }

    @media (min-width: 992px) {
        .form-group .input-group.date {
            width: 115px;
        }


        &--large {
            .form-group {
                .input-group.date {
                    width: 200px;
                }

                &:first-child label {
                    width: 200px !important;
                }
            }
        }
    }

    @media (min-width: 1200px) {
        .form-group:first-child label {
            width: 100px !important;
        }

        .form-group .input-group.date {
            width: 145px;
        }

        &--large {
            .form-group {
                .input-group.date {
                    width: 250px !important;
                }

                &:first-child label {
                    width: 200px !important;
                }
            }
        }
    }

    @media (min-width: 1260px) {
        .form-group .input-group.date {
            width: 160px;
        }
    }

    @media (min-width: 1630px) {
        .form-group .input-group.date {
            width: 245px;
        }
    }
}

.form-contact {
    background-color: $color-white;
    border-radius: 3px;
    padding: 30px;

    &__title {
        font-size: 24px;
        color: $color-blue-80;
        margin-bottom: 30px;
    }

    .btn {
        line-height: 1;
    }
}

.form-text {
    i {
        font-size: 18px;
    }

    &__blue-80 {
        color: $color-blue-80;
    }
}

.rte-container {
    width: auto;

    &-wrapper {

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: $color-blue-80;
        }

        &.error::after {
            background-color: #dc3545;
        }

        .rte-editor {
            min-height: 200px;
            border: solid $color-body 1px;
            padding: 0 8px;
        }
    }
}