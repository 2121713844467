html,
body {
    height: 100%;
}

body {
    // max-width: 1920px;
    // margin: auto;
    font-family: $font-arial;
    font-size: 16px;
    line-height: 18px;
    background-color: $color-body;
    color: $color-main;
    overflow-wrap: break-word;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    @media (min-width: 1260px) {
        max-width: 1230px;
    }

    @media (min-width: 1630px) {
        max-width: 1600px;
    }

    &--md {
        max-width: 800px;
    }
}

@each $name, $mobile-size, $desktop-size in $font-sizes {
    #{$name} {
        font-size: $mobile-size;

        @media (min-width: 992px) {
            font-size: $desktop-size;
        }
    }
}

ul,
ol {
    ul,
    ol {
        margin-bottom: 1rem;
    }
}

a,
.link {
    padding: 0;
    font-family: $font-arial;
    appearance: none;
    border: 0;
    background-color: unset;
    text-decoration: underline;
    text-underline-offset: 3px;
    transition: .15s all;

    &:hover {
        text-decoration-color: transparent;
    }
}

.link {
    color: $color-blue-80 !important;

    &--white {
        color: $color-white !important;
    }

    &--strong {
        font-weight: 700;
    }

    &--cell {
        color: $color-main !important;
        text-decoration: none;
    }
}

.link-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
}

.main-title {
    font-size: 24px;
    margin: 0 0 20px;

    @media (min-width: 992px) {
        font-size: 28px;
        margin: 0 0 40px;
    }
}

.text-blue-80 {
    color: $color-blue-80;
}

.text-error {
    color: $color-error;

    &--strong {
        color: $color-error;
        font-weight: 700;
    }
}