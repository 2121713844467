.page-login {
    background: url(/assets/images/login_background.svg) no-repeat left top;
    background-size: cover;
    position: relative;
    // height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) {
        background-position: bottom center;
        padding: 30px 30px 0;
        min-height: 800px;
    }

    &__foreground {
        min-height: 200px;
        width: 100%;
        object-fit: cover;
        object-position: left bottom;
        pointer-events: none;
        margin-top: auto;

        @media (min-width: 992px) {
            position: absolute;
            bottom: 0;
            left: 0;
            margin-top: unset;
        }
    }

    &__header {
        padding: 18px 18px 0;

        @media (min-width: 992px) {
            position: absolute;
            top: 30px;
            left: 30px;
            padding: 0;
        }
    }

    &__logo {
        width: 48px;

        @media (min-width: 992px) {
            width: 118px;
            padding: 4px;
            background: $color-white;
        }
    }

    &__title {
        text-align: center;
        margin: 0 0 20px;

        img {
            width: 190px;
        }
    }

    &__intro {
        text-align: center;
        font-size: 15px;
        line-height: 18px;
        color: $color-blue-100;
        margin: 0 0 30px;
        font-family: $font-roboto;

        @media (min-width: 992px) {
            margin: 0 0 40px;
        }
    }

    &__action-title {
        font-family: $font-officina;
        font-weight: 700;
        font-size: 26px;
        line-height: 36px;
        color: $color-blue-80;
        margin: 0 0 9px;
        text-align: center;
    }

    &__action-description {
        font-family: $font-roboto;
        font-size: 15px;
        line-height: 18px;
        color: $color-main;
        margin-bottom: 30px;
        text-align: center;
    }

    &__form {
        width: 440px;
        max-width: 100%;
        margin: auto;
        padding: 0 24px;
        flex-shrink: 0;

        @media (min-width: 992px) {
            background: $color-white;
            border-radius: 6px;
            flex: 1;
            padding: 30px 40px 200px;
        }
    }

    &__wrapper {
        padding: 15px;
        background: transparent;
        flex-shrink: 0;

        @media (min-width: 992px) {
            padding: 130px 40px 200px;
        }

        @media (min-width: 1200px) {
            padding: 30px 140px 250px;
        }

        @media (min-width: 1850px) {
            padding: 30px 40px 300px;
        }
    }

    &__legal-notice {
        border-radius: 6px;
        padding: 30px 15px;
        background: $color-white;

        h1,
        h2,
        h3,
        h4 {
            color: $color-blue-80;
        }

        a {
            word-break: break-all;
        }

        font-size: 12px;

        @media (min-width: 768px) {
            padding: 30px;
            font-size: 14px;
        }

        @media (min-width: 992px) {
            padding: 60px 40px 200px;
            font-size: 16px;
        }
    }

    &__footer {
        display: block;
        width: 100%;
        z-index: 1;
        background-color: #6bc159;
        order: 1;
        position: relative;
        top: -1px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-flow: row wrap;

        @media (min-width: 992px) {
            position: absolute;
            top: unset;
            bottom: 0;
            left: 0;
            background-color: transparent;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 15px;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: 10px 24px;
            margin-left: auto;

            a {
                color: $color-white;
            }
        }
    }

    label {
        font-size: 18px;
    }
}