.pagination {
    justify-content: center;

    .page-item {
        background-color: transparent;
        padding: 0 2px;

        .page-link {
            appearance: none;
            border-radius: 40px;
            border: 0;
            min-width: 30px;
            height: 30px;
            text-decoration: none;
            line-height: 30px;
            text-align: center;
            padding: 0 4px;
            font-size: 16px;
            background: transparent;
            color: $color-blue-80;
            box-shadow: none !important;

            &:hover,
            &:focus-visible {
                background-color: $color-blue-60;
            }
        }


        &.active {
            .page-link {
                background-color: $color-blue-80;
                color: $color-white;
                font-weight: 700;
            }
        }

        &-prev,
        &-next {
            .page-link {
                border-radius: 2px;
                width: auto;
                padding: 0 6px;
                background: transparent;
                display: flex;
                align-items: center;

                @media (max-width: 767px) {
                    font-size: 0;
                }
            }
        }

        &-prev .page-link::before {
            @include icomoon;
            content: "\e90e";
            font-size: 18px;
            margin-right: 6px;
        }

        &-next .page-link::after {
            @include icomoon;
            content: "\e90d";
            margin-left: 6px;
            font-size: 18px;
        }

        &-first,
        &-last {
            i {
                position: relative;
                top: 1px;
            }
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;

            .page-link {
                &:hover,
                &:focus {
                    outline: none;
                    background-color: transparent;
                }
            }
        }
    }
}