.select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 6px 0;

    @media (min-width: 992px) {
        justify-content: flex-end;
    }

    label {
        margin: 0 30px 0 0;
        font-size: 14px;
        color: $color-main;
        margin-left: auto;
    }

    .form-control {
        width: auto;
        height: 32px;
        padding: 8px 32px 8px 8px;
        border-radius: 0;
        border: 0;
        appearance: none;
        line-height: normal;
        background-color: $color-white;
        background-image: url(/assets/images/icons/expand-more.svg);
        background-repeat: no-repeat;
        background-position: center right 4px;
        font-size: 14px;
        font-weight: 700;

        &:focus {
            background-image: url(/assets/images/icons/expand-less.svg);
        }
    }

    &--rounded {
        .form-control {
            border-radius: 18px;
        }
    }
}