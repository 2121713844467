.react-datepicker__input-container input {
    border: 0;
    width: inherit;
}

.form-group .input-group.date:before {
    top: 1px;
}

.dropdown-checkbox__toggle.error {
    border: 1px solid #dc3545;
}

.dropdown-invalid-feedback {
    margin-top: 5px;
    margin-left: 16px;
    font-size: 80%;
    color: #dc3545;
}

.dropdown-invalid-feedback:before {
    content: "×";
    color: #FFF;
    background-color: #ff6b6b;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    display: inline-block;
    margin-right: 6px;
}

.form-group .infos-feedback {
    font-size: 14px;
    color: #0C7FB8;
    width: 100%;
}

.form-group .infos-feedback:before {
    content: "i";
    color: #FFF;
    background-color: #0C7FB8;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    display: inline-block;
    margin-right: 6px;
}

.pagination .page-item.active  .page-link.secondary {
    background: white;
    color: #0C7FB8;
}

.pagination .page-item .page-link.secondary {
    color: white;
}

.accordion-item:not(:last-child):before {
    background-color: #707070;
    color: #707070;
    content: "";
    display: block;
    height: 100%;
    left: 15px;
    position: absolute;
    top: 22px;
    width: 1px;
}

.accordion-tree .accordion-tree:not(:last-child):before {
    background-color: #707070;
    color: #707070;
    content: "";
    display: block;
    height: 80%;
    left: 45px;
    position: absolute;
    top: 45px;
    width: 1px;
}