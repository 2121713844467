.loading-indicator {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;

    &__wrapper {
        pointer-events: none;

        &--active {
            pointer-events: all;
        }
    }


    &__overlay {
        background: transparentize($color-white, 0.5) !important;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: $color-blue-100;
        font-size: 16px;
    }

    &__spinner {
        margin-bottom: 10px;
    }
}