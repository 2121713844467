.breadcrumb {
    padding: 20px 0;
    margin: 0;
    background-color: transparent;

    &-icon {
        color: $color-blue-80;
        margin-right: 8px;
    }

    &-item {
        font-size: 10px;

        @media (min-width: 992px) {
            font-size: 14px;
        }

        a {
            color: inherit;
        }

        &.active {
            font-weight: 700;
        }

        & + .breadcrumb-item {
            padding-left: 20px;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 8px;
                top: 6px;
                display: inline-block;
                width: 6px;
                height: 6px;
                border-right: 1px solid;
                border-top: 1px solid;
                padding: 0;
                transform: rotate(45deg);
                margin-right: 4px;
                flex-shrink: 0;
            }
        }
    }
}