.dropdown {
    &-menu {
        margin-top: 10px;
        padding: 0;
        box-shadow: $shadow-sm;
        width: max-content;
        max-width: 240px;
        word-break: break-word;
    }

    &-item {
        font-size: 16px;
        color: $color-main;
        text-decoration-color: transparent;
        transition: .25s all;
        padding: 9px 16px;
        white-space: unset;
        color: #575757;
        text-decoration: none;
        cursor: pointer;

        a {
            font-size: 16px;
            color: #575757;
            text-decoration: none;
        }

        &:hover {
            text-decoration: none;
            background: $color-blue-0;
            color: $color-main;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $color-body;
        }
    }

    &-title {
        color: $color-main;
        margin: 0 16px 7px;
        padding: 7px 0;
        font-size: 16px;
        border-bottom: 1px solid $color-grey;
    }
}

.dropdown-checkbox {
    width: 280px;
    max-width: 100%;

    &__toggle {
        appearance: none;
        background-color: $color-white;
        border-radius: 18px;
        height: 36px;
        padding: 0 40px 0 16px;
        font-size: 16px;
        color: $color-main;
        line-height: 36px;
        border: 0;
        width: 100%;
        text-align: left;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include dropdown;
    }

    &__list {
        width: 280px;
        max-width: 100%;

        li {
            padding: 8px 10px;
            display: flex;
            align-items: center;

            .checkbox {
                flex-shrink: 0;
                margin-right: 14px;
            }

            label {
                margin: 0;
                color: $color-main;
                font-size: 15px;
                line-height: 17px;
                font-weight: 400;
                cursor: pointer;
                flex: 1;
            }
        }
    }
}