.footer {
    margin-top: auto;

    @media (min-width: 992px) {
        background-color: $color-blue-80;
        color: $color-white;
    }

    &__container {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-flow: row wrap;
    }

    &__logo {
        display: none;

        @media (min-width: 992px) {
            width: 40px;
            display: block;
        }
    }

    &__links {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex: 1;
        justify-content: center;

        @media (min-width: 992px) {
            justify-content: flex-start;
            margin-left: 40px;
            gap: 40px;
        }

        li {
            .btn.btn-link {
                color: $color-main;
                text-decoration: underline;

                &:hover {
                    background-color: $color-blue-80;
                    color: $color-white;
                    text-decoration-color: transparent;
                }

                @media (min-width: 992px) {
                    color: $color-white;
                    text-decoration: none;

                    &:hover {
                        background-color: transparentize($color-white, 0.9);
                    }
                }
            }
        }
    }
}

.app-version {
    padding: 15px;
    color: $color-white;
    font-size: 12px;
    font-weight: bold;

    &__variant {
        @media (max-width: 991px) {
            color: $color-main;
        }
    }
}