.tourneeDropdown {

    &__info {
        display: flex;

        @media (max-width: 991px) {
            flex-direction: column;
        }
    }

    &__item {
        margin-right: 35px;
        text-align: left;
        display: flex;
        white-space: pre-wrap;

        @media (max-width: 1199px) {
            flex-direction: column;
            margin-top: 5px;
            line-height: 15px;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 18px;
        margin: 0 50px 0 0;
    }

    &__bold {
        font-weight: bold;
    }

    &__btn {
        position: relative;
        background-color: $color-blue-100;
        color: $color-white;
        width: 100%;
        border: none;
        padding: 10px 12px 10px 12px;
        margin-bottom: 5px;
        font-size: 13px;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
        }

        &:after {
            @include icomoon;
            content: "\e910";
            font-size: 32px;
            position: absolute;
            right: 16px;
        }

        &[aria-expanded="true"]:after {
            content: "\e911";
        }
    }
}