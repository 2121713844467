.modal {
    &-backdrop {
        background-color: $color-white;
        opacity: .08;
    }

    &-dialog {
        width: calc(100% - 16px) !important;
        max-width: 550px !important;
        transform: scale(0);
        transition: .25s all;
    }

    &.show .modal-dialog {
        transform: none;
    }

    &-content {
        background: $color-blue-80;
        color: $color-white;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        border: none;
        padding: 50px 30px 30px 30px;
        max-height: calc(100vh - 56px);
        overflow-y: auto;
    }

    &-header,
    &-footer {
        justify-content: center;
        border: none;
        padding: 0;
    }

    &-footer {
        padding-top: 30px;
    }

    &-body {
        padding: 0 0 14px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    &-title {
        font-family: $font-officina;
        font-size: 26px;
        line-height: 30px;
        font-weight: 700;
        text-align: center;
        margin: 0 0 10px;
    }

    &-subtitle {
        font-family: $font-officina;
        text-align: left;
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 20px;
    }

    &--md {
        .modal-dialog {
            max-width: 880px !important;
        }
    }

    &--lg {
        .modal-dialog {
            max-width: 1170px !important;
        }
    }

    &--xl {
        .modal-dialog {
            max-width: 1570px !important;
        }
    }

    &--data {
        text-align: left;

        .modal-header {
            justify-content: flex-start;
        }

        .modal-content {
            text-align: left;
            overflow-y: unset;
        }

        .modal-body {
            overflow: auto;
            flex: 1;
        }
    }

    hr {
        border-color: $color-white;
        opacity: .5;
    }
}

.modal-step {
    margin-bottom: 20px;

    &__title {
        text-align: left;
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 20px;

        @media (min-width: 1200px) {
            font-size: 24px;
        }
    }

    &__container {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__steps {
        counter-reset: step;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0 0 20px 0;

        li {
            counter-increment: step;
            font-size: 16px;
            margin: 0 30px 10px 0;
            display: flex;
            align-items: center;
            opacity: 0.5;
            text-align: left;

            &.active {
                opacity: 1;
            }

            @media (min-width: 1200px) {
                font-size: 20px;
                margin-right: 50px;
            }

            &:before {
                content: counter(step);
                display: inline-block;
                text-align: center;
                font-size: 15px;
                line-height: 24px;
                font-weight: 700;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: $color-blue-60;
                margin-right: 10px;
                flex-shrink: 0;

                @media (min-width: 1200px) {
                    margin-right: 15px;
                }
            }
        }

        button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
        }
    }

    &__actions {
        margin: 0 0 20px auto;
    }
}

.annonce-details {
    height: fit-content;
    max-height: 100%;

    &__title {
        margin-bottom: 30px;
    }

    &>* {
        flex-grow: 1;
    }

    &-body {
        display: flex;
        gap: 24px;
        height: fit-content;
        max-height: 100%;
        background-color: $color-white;
        border-radius: 5px;
        padding: 32px 16px;
        color: $color-blue-100;

        @media (max-width: 991px) {
            flex-direction: column;
            gap: 40px;
            max-height: calc(100vh - 370px);
            overflow-y: auto;
        }

        &__files {
            width: 330px;
            min-width: 330px;
            height: fit-content;

            @media (max-width: 475px) {
                width: auto;
                min-width: 0;
            }


            img {
                width: 100%;
                height: fit-content;
                max-height: 330px;
                object-fit: contain;
            }

            ul {
                max-width: 100%;
                margin-top: 30px;
                width: fit-content;

                li:not(:first-child) {
                    margin-top: 10px;
                }

                li {
                    max-width: 100%;
                    color: $color-blue-80;
                    background-color: $color-body;
                    padding: 10px 16px;

                    button {
                        border: none;
                        text-decoration: underline;
                    }

                    a, button {
                        color: $color-blue-100;
                        display: block;
                        max-width: 100%;
                        line-height: 20px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
            }
        }

        &__text {
            max-height: calc(100vh - 370px);
            flex-grow: 1;
            overflow-y: auto;
            padding-right: 15px;

            ul {
                list-style: disc inside;
            }

            @media (max-width: 991px) {
                max-height: unset;
                overflow-y: unset;
            }
        }
    }
}
