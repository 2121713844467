.date {
    .react-datepicker {
        &__close-icon {
            background-color: $color-white;
            z-index: 2;
            width: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
        }

        &-popper {
            z-index: 95;
        }

        &__input-container {
            input {
                padding-right: 2rem;
            }
        }
    }
}
