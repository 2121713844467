.nav-tabs {
    padding-bottom: 6px;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: auto;
    border: 0;
    flex: 1;

    .nav-item {
        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    .nav-link {
        border: 0;
        background-color: $color-blue-80;
        color: $color-white;
        padding: 8px 14px 10px;
        border-radius: 6px 6px 0 0;
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
        height: 41px;
        opacity: 0.6;
        display: flex;
        align-items: center;

        &.disabled,
        &.active {
            background-color: $color-blue-80;
            color: $color-white;
        }

        &.active {
            opacity: 1;
        }

        &.disabled {
            opacity: 0.2;
        }

        i {
            font-size: 18px;
            margin-right: 8px;
            color: $color-blue-60;
            pointer-events: none;
        }
    }

    .tab-icon {
        height: 48px;
        display: flex;
        align-items: center;
        text-align: left;
        font-weight: 400;
        line-height: 1;

        &__icon {
            height: 25px;
            color: $color-blue-60;
            display: none;
            margin-right: 8px;

            @media (min-width: 992px) {
                display: block;
            }

            &--small {
                height: 16px;
            }
        }

        &__type {
            font-size: 12px;
            margin-bottom: 3px;

            @media (min-width: 992px) {
                font-size: 15px;
            }
        }

        &__info {
            font-size: 11px;
        }
    }
}