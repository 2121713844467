.charts-container {
    background-color: $color-white;
    padding: 20px;

    &--scrollable {
        .charts-content {
            cursor: move;
        }
    }
}

.charts-errors {
    padding: 0;
    list-style: none;
    margin: 0 0 30px;

    li {
        margin-bottom: 5px;
        color: $color-main;
        font-weight: 700;
        font-style: italic;
        font-size: 12px;
        color: $color-error;

        @media (min-width: 992px) {
            font-size: 14px;
        }

        i {
            font-size: 1.3em;
            margin-right: 10px;
            position: relative;
            top: 2px;
        }
    }
}

.charts-legend {
    padding: 0;
    margin: 0 0 30px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    &__item {
        display: flex;
        align-items: center;
        margin-right: 30px;
        font-size: 14px;
        font-weight: 700;
    }

    &__color {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 10px;
        border-radius: 3px;
    }
}

.charts-axis {
    padding: 0;
    margin: 30px 0 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 700;

    &--bottom {
        margin: 0 0 30px;
        justify-content: center;
    }
}

.charts-filters {
    background-color: $color-blue-80;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px;

    @media (min-width: 1260px) {
        padding: 0 15px;
    }

    .form-control {
        margin: 0;
        height: 32px;
    }

    &__section {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        padding: 10px;

        @media (min-width: 1260px) {
            width: auto;
            flex: 2;
            padding: 15px;
        }
    }

    &__label {
        font-size: 16px;
        color: $color-white;
        margin: 0;
        width: 100%;

        @media (min-width: 768px) {
            width: auto;

            &:first-child {
                width: 200px;
            }
        }

        @media (min-width: 1260px) {
            max-width: unset;

            &:first-child {
                width: unset;
            }
        }
    }

    &__input {
        width: 100%;

        label {
            display: none;
        }

        @media (min-width: 499px) {
            width: auto;
            flex: 1;
        }
    }

    &__submit {
        margin-left: auto;
        flex-shrink: 0;
        display: flex;

        @media (min-width: 1260px) {
            flex: 1;
        }

        .btn {
            height: 32px;
            margin-left: auto;
            padding: 4px 16px;
        }
    }
}

.charts-pdf {
    display: flex;
    flex-wrap: wrap;

    &__header,
    &__content,
    &__footer,
    &__trigger {
        width: 100%;
    }

    &__header,
    &__footer {
        display: none;
        background-color: $color-white;
        font-size: 8px;
        line-height: 1.2;
        color: black;
    }

    .charts-pdf-content {
        display: none;
    }

    &__footer {
        justify-content: space-between;
    }

    &__logo {
        height: 70px;
        width: 70px;
        object-fit: contain;
        position: relative;
        top: -10px;
    }

    &__title {
        text-align: center;
    }

    &.print {
        width: 842px;
        padding: 10px;

        .btn {
            display: none;
        }

        .charts-pdf__header {
            display: block;
        }

        .charts-pdf__footer {
            display: flex;
        }

        .charts-pdf__content {
            padding: 0 10px;
        }

        .charts-content {
            display: none;
        }

        .charts-pdf-content {
            display: block;
        }

        .charts-errors {
            margin-bottom: 15px;
            font-size: 8px;
        }

        .charts-axis {
            font-size: 8px;
            margin-top: 15px;
        }

        .charts-legend {
            margin-bottom: 15px;

            &__item {
                margin-right: 15px;
            }

            &__color {
                width: 8px;
                height: 8px;
                margin-right: 4px;
            }

            &__text {
                font-size: 8px;
            }
        }
    }
}