@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?pocm4y');
  src:  url('fonts/icomoon.eot?pocm4y#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?pocm4y') format('truetype'),
    url('fonts/icomoon.woff?pocm4y') format('woff'),
    url('fonts/icomoon.svg?pocm4y#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-api:before {
  content: "\e929";
}
.icon-tree:before {
  content: "\e927";
}
.icon-people:before {
  content: "\e928";
}
.icon-cancel:before {
  content: "\e926";
}
.icon-move:before {
  content: "\e925";
}
.icon-remove:before {
  content: "\e924";
}
.icon-add:before {
  content: "\e920";
}
.icon-list:before {
  content: "\e921";
}
.icon-refresh:before {
  content: "\e922";
}
.icon-block:before {
  content: "\e923";
}
.icon-tune:before {
  content: "\e91e";
}
.icon-settings:before {
  content: "\e91f";
}
.icon-plus:before {
  content: "\e91d";
}
.icon-first:before {
  content: "\e91b";
}
.icon-last:before {
  content: "\e91c";
}
.icon-close:before {
  content: "\e91a";
}
.icon-city:before {
  content: "\e916";
}
.icon-group:before {
  content: "\e917";
}
.icon-person:before {
  content: "\e918";
}
.icon-workspace:before {
  content: "\e919";
}
.icon-calendar:before {
  content: "\e915";
}
.icon-pen:before {
  content: "\e914";
}
.icon-trash:before {
  content: "\e913";
}
.icon-add-circle:before {
  content: "\e912";
}
.icon-expand-more:before {
  content: "\e910";
}
.icon-expand-less:before {
  content: "\e911";
}
.icon-check:before {
  content: "\e90f";
}
.icon-file:before {
  content: "\e90a";
}
.icon-import:before {
  content: "\e90b";
}
.icon-export:before {
  content: "\e90c";
}
.icon-next:before {
  content: "\e90d";
}
.icon-previous:before {
  content: "\e90e";
}
.icon-actions:before {
  content: "\e909";
}
.icon-home:before {
  content: "\e908";
}
.icon-sort:before {
  content: "\e904";
}
.icon-sort-asc:before {
  content: "\e905";
}
.icon-sort-desc:before {
  content: "\e906";
}
.icon-simulate:before {
  content: "\e907";
}
.icon-bell:before {
  content: "\e900";
}
.icon-eye:before {
  content: "\e901";
}
.icon-eye-close:before {
  content: "\e902";
}
.icon-info:before {
  content: "\e903";
}
