.panel {
    background-color: $color-white;
    margin-bottom: 10px;

    &__title {
        font-size: 18px;
        margin: 0;
        padding: 20px 13px 0;
        color: $color-blue-80;
        display: flex;
        align-items: flex-start;

        @media (min-width: 992px) {
            font-size: 24px;
            padding: 30px 30px 0;
        }
    }

    &__section {
        background-color: $color-blue-20;
        padding: 20px;
        display: flex;
        flex-direction: column;

        &:not(:first-child) {
            margin-top: 5px;
        }
    }

    &__toggle {
        padding: 0;

        button {
            padding: 20px 45px 20px 13px;
            appearance: none;
            background-color: transparent;
            width: 100%;
            font-size: inherit;
            color: inherit;
            text-align: unset;
            border: 0;
            border-bottom: 1px solid transparent;
            position: relative;

            @media (min-width: 992px) {
                padding: 30px 62px 30px 30px;
            }

            &:after {
                @include icomoon;
                content: "\e910";
                font-size: 32px;
                position: absolute;
                top: 16px;
                right: 13px;

                @media (min-width: 992px) {
                    top: 30px;
                    right: 30px;
                }
            }

            &[aria-expanded="true"] {
                border-color: $color-blue-0;

                &:after {
                    content: "\e911";
                }
            }

            &:hover {
                background-color: $color-blue-40;
            }
        }
    }

    &__content {
        padding: 20px 13px;

        @media (min-width: 992px) {
            padding: 30px;
        }
    }
}
