.header {
    background: $color-white;
    box-shadow: $shadow-sm;
    height: 75px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 500;

    &__logo {
        width: 100px;

        @media (min-width: 768px) {
            width: 140px;
        }
    }

    &__actions {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: 10px;
        align-items: center;

        @media (min-width: 992px) {
            gap: 17px;
        }

        .dropdown-toggle {
            &:after {
                @media (max-width: 991px) {
                    content: none;
                }
            }
        }
    }

    &__account {
        display: flex;
        gap: 8px;
        align-items: center;
        text-decoration: none;
        color: $color-main;
        font-size: 16px;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        strong {
            flex: 1;
            max-width: 120px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .navbar {
        margin: auto;
        max-width: 1230px;
        width: 100%;

        @media (min-width: 1630px) {
            max-width: 1600px;
        }

        &-toggler {
            border-radius: 0;
            width: 28px;
            height: 32px;
            order: 2;
            margin-left: 10px;
            position: relative;

            &:before,
            &:after,
            .icon {
                content: "";
                width: 20px;
                height: 2px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: $color-blue-80;
                transition: 0.25s all;
            }

            &:before {
                margin-top: -6px;
            }

            &:after {
                margin-top: 6px;
            }

            &[aria-expanded="true"] {
                .icon {
                    opacity: 0;
                }

                &:before {
                    margin: 0;
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:after {
                    margin: 0;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }

        &-collapse {
            @media (max-width: 991px) {
                position: fixed;
                top: 75px;
                left: 0;
                width: 100%;
                background-color: $color-white;
                padding: 10px 0;
                box-shadow: $shadow-sm;
            }
        }
    }

    .nav-item {
        color: $color-main;
        line-height: 1;
        text-transform: uppercase;
        transition: .25s all;

        &:hover {
            background-color: $color-body;
        }

        @media (min-width: 992px) {
            &:not(:last-child) {
                border-right: 1px solid transparentize($color-main, .6);
            }
        }

        .nav-link {
            color: inherit;
            text-decoration: none;
            font-size: 14px;
            padding: 12px 15px;

            &.active {
                color: $color-blue-80;
                font-weight: 700;
            }

            @media (min-width: 992px) {
                font-size: 12px;
                padding: 6px 10px;
            }

            @media (min-width: 1200px) {
                font-size: 14px;
                padding: 11px 20px;
            }
        }
    }

    &__submenu {
        text-transform: none;
        font-size: 14px;
        padding: 10px 0 30px;

        .container {
            display: block;
            max-width: 800px;
        }

        &-col {
            @media (min-width: 992px) {
                padding: 0 30px;

                &:not(:last-child) {
                    border-right: 1px solid transparentize($color-main, .6);
                }
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .nav-link {
            font-weight: 700;

            @media (min-width: 992px) {
                padding: 6px 0 !important;
            }

            & + ul {
                padding-left: 15px;

                .nav-link {
                    color: $color-main;
                    text-decoration: none;
                    display: inline-block;
                    font-weight: 400;
                }
            }
        }

        @media (min-width: 992px) {
            position: fixed;
            top: 75px;
            left: 0;
            width: 100%;
            background: $color-white;
            box-shadow: $shadow-sm;
        }
    }
}

.header-disconnect {
    padding: 0 15px;
    margin: 35px auto 25px;
    max-width: 1230px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (min-width: 1630px) {
        max-width: 1600px;
    }

    &__logo {
        width: 90px;
    }
}