.btn-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    &--center {
        justify-content: center;
    }
}

.btn {
    font-size: 14px;
    padding: 7px 16px;
    border-width: 2px;
    border-radius: 18px;
    text-decoration: none;
    color: $color-blue-80;
    border-color: transparent;

    i {
        font-size: 16px;
        position: relative;
        top: 2px;
        margin-right: 4px;
    }

    &:hover {
        color: $color-blue-80;
        border-color: $color-blue-80;
        text-decoration: none;
    }

    &.btn-small {
        height: 24px;
        padding: 5px 16px 4px;
        font-size: 12px;
        line-height: 12px;
    }

    &.btn-lh-1 {
        line-height: 1;
    }

    &.btn-white {
        border-color: transparent;
        background-color: transparent;
        color: $color-white;

        &:hover {
            border-color: $color-white;
            color: $color-white;
        }
    }

    &.btn-primary {
        border-color: $color-blue-60;
        background-color: $color-blue-60;
        color: $color-white;

        &:hover {
            color: $color-white;
            border-color: $color-blue-80;
            background-color: $color-blue-80;
        }
    }

    &.btn-secondary {
        border-color: $color-green;
        background-color: $color-green;
        color: $color-white;

        &:hover {
            color: $color-green;
            background-color: transparent;
        }
    }

    &.btn-danger {
        border-color: $color-error;
        background-color: transparent;
        color: $color-error;

        &:hover {
            color: $color-white;
            background-color: $color-error;
        }
    }

    &.btn-alert {
        border-color: $color-error;
        background-color: $color-error;
        color: $color-white;

        &:hover {
            color: $color-white;
            background-color: $color-error;
        }
    }

    &.btn-alert {
        border-color: $color-error;
        background-color: $color-error;
        color: $color-white;

        &:hover {
            border-color:lighten($color-error, 10);
            background-color: lighten($color-error, 10);
        }

    }

    &.btn-dark {
        border-color: $color-blue-80;
        background-color: $color-blue-80;
        color: $color-white;

        &:hover {
            border-color: $color-blue-60;
            background-color: $color-blue-60;
        }
    }

    &.btn-success {
        border-color: $color-green;
        background-color: $color-green;
        color: $color-white;

        &:hover {
            border-color: $color-green-light;
            background-color: $color-green-light;
        }
    }

    &.btn-link {
        color: $color-blue-80;

        &:hover {
            border-color: $color-blue-80;
        }
    }

    &.disabled {
        opacity: .5;
        cursor: unset;
        pointer-events: none;
    }

    &.hidden {
        opacity: 0;
    }
}

.btn-icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 16px;
    color: $color-main;
    position: relative;
    transition: .15s ease-in-out;

    i {
        font-size: 24px;
        pointer-events: none;
    }

    &--sm {
        i {
            font-size: 20px;
        }
    }

    @media (min-width: 992px) {
        width: 34px;
        height: 34px;
    }

    &:hover {
        text-decoration: none;
        color: $color-blue-80;
    }

    &--white {
        color: $color-main;

        &:hover {
            background-color: $color-grey;
            color: $color-white;
        }
    }

    &--primary {
        background-color: transparent;
        color: $color-blue-100;

        &:hover {
            background-color: $color-blue-100;
            color: $color-white;
        }
    }

    &--secondary {
        background-color: transparent;
        color: $color-white;

        &:hover {
            background-color: $color-green;
            color: $color-white;
        }
    }

    &--blue {
        background-color: $color-blue-80;
        color: $color-white;

        &:hover {
            background-color: $color-blue-40;
            color: $color-white;
        }
    }

    &--green {
        background-color: $color-green;
        color: $color-white;

        &:hover {
            background-color: $color-green-light;
            color: $color-white;
        }
    }

    &--grey {
        background-color: $color-grey-dark;
        color: $color-white;

        &:hover {
            background-color: $color-grey;
            color: $color-white;
        }
    }

    &--text {
        font-size: 0;

        i {
            font-size: 14px;
        }

        @media (min-width: 992px) {
            font-size: 14px;
            width: unset;
            height: 24px;
            border-radius: 18px;
            padding: 5px 16px;

            i {
                position: relative;
                top: -1px;
                margin-right: 10px;
            }
        }
    }

    &.has-notifications {
        &:after {
            content: "";
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $color-error;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &.disabled {
        opacity: .5;
        cursor: unset;
        pointer-events: none;
    }

    &.hidden {
        opacity: 0;
    }
}

.action-btn {
    width: 24px;
    height: 24px;
    border: 1px transparent;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-blue-80;
    text-decoration: none;

    i {
        font-size: 16px;
        pointer-events: none;
    }

    &--success {
        background-color: $color-green;
        color: $color-white;
        border-radius: 50%;
        border: 1px solid $color-green;

        &.active {
            border-color: $color-green;
            background-color: transparent;
        }

        &:hover,
        &:focus {
            background-color: $color-green-light;
        }
    }

    &--light {
        background-color: $color-blue-20;
        color: $color-blue-80;
        border-radius: 50%;
        border: 1px solid $color-blue-20;

        &.active {
            border-color: $color-blue-80;
            background-color: transparent;
        }

        &:hover,
        &:focus {
            color: $color-white;
            border-color: $color-blue-80;
            background-color: $color-blue-80;
        }
    }

    &--large {
        width: 32px;
        height: 32px;

        i {
            font-size: 24px;
            font-weight: 700;
        }
    }

    &--collapse {
        &:after {
            @include icomoon;
            content: '\e910';
        }

        &[aria-expanded=true] {
            &::after{
                content: '\e911';
            }
        }
    }

    &.disabled {
        opacity: .5;
        cursor: unset;
        pointer-events: none;
    }

    &.hidden {
        opacity: 0;
    }
}
