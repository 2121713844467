@font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto-Regular.woff2);
    font-display: swap;
}

// Font sizes (mobile / desktop)
$font-sizes: (
    "h1, .h1" 2em 2.24em,
    "h2, .h2" 1.7em 2em,
    "h3, .h3" 1.6em 1.7em,
    "h4, .h4" 1.4em 1.4em,
    "h5, .h5" 1.2em 1.2em,
    "h6, .h6" 1.1em 1.1em,
);

$font-arial: Arial, Helvetica, sans-serif;
// $font-officina: 'Officina', sans-serif;
$font-officina: $font-arial;
$font-roboto: 'Roboto', sans-serif;

$color-white: #FFF;
$color-blue-100: #1D609D;
$color-blue-80: #0C7FB8;
$color-blue-60: #01B7ED;
$color-blue-40: #C7F5FC;
$color-blue-20: #DDF0F5;
$color-blue-0: #F2FCFE;
$color-green: #6BC159;
$color-green-light: #90c486;
$color-grey: #D9D9D9;
$color-grey-dark: #B9B9B9;
$color-main: #575757;
$color-body: #efefee;
$color-error: #ff6b6b;

$shadow-sm: 0 0 6px #00000029;
$shadow-md: 0 0 8px #00000029;
$shadow-menu: 0 6 6px #00000029;
