$spacing-breakpoints: ('-': 0px, '-sm-': 576px, '-md-': 768px, '-lg-': 992px, '-xl-': 1200px);
$spacings: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50;

$column-prefixes: 'col-', 'col-sm-', 'col-md-', 'col-lg-', 'col-xl-';

@each $breakpoint, $width in $spacing-breakpoints {
    @each $spacing in $spacings {
        @media (min-width: #{$width}) {
            .m#{$breakpoint}#{$spacing} {
                margin: #{$spacing}px !important;
            }

            .m-r#{$breakpoint}#{$spacing},
            .m-x#{$breakpoint}#{$spacing} {
                margin-right: #{$spacing}px !important;
            }

            .m-l#{$breakpoint}#{$spacing},
            .m-x#{$breakpoint}#{$spacing} {
                margin-left: #{$spacing}px !important;
            }

            .m-t#{$breakpoint}#{$spacing},
            .m-y#{$breakpoint}#{$spacing} {
                margin-top: #{$spacing}px !important;
            }

            .m-b#{$breakpoint}#{$spacing},
            .m-y#{$breakpoint}#{$spacing} {
                margin-bottom: #{$spacing}px !important;
            }

            .p#{$breakpoint}#{$spacing} {
                padding: #{$spacing}px !important;
            }

            .p-r#{$breakpoint}#{$spacing},
            .p-x#{$breakpoint}#{$spacing} {
                padding-right: #{$spacing}px !important;
            }

            .p-l#{$breakpoint}#{$spacing},
            .p-x#{$breakpoint}#{$spacing} {
                padding-left: #{$spacing}px !important;
            }

            .p-t#{$breakpoint}#{$spacing},
            .p-y#{$breakpoint}#{$spacing} {
                padding-top: #{$spacing}px !important;
            }

            .p-b#{$breakpoint}#{$spacing},
            .p-y#{$breakpoint}#{$spacing} {
                padding-bottom: #{$spacing}px !important;
            }
        }
    }
}

.mobile-only {
    @media (min-width: 768px) {
        display: none !important;
    }
}

.desktop-only {
    @media (max-width: 767px) {
        display: none !important;
    }
}

.large-gutters {
    overflow: hidden;

    @media (min-width: 576px) {
        overflow: unset;
    }

    .row {
        margin-left: -30px;
        margin-right: -30px;

        @media (min-width: 1200px) {
            margin-left: -50px;
            margin-right: -50px;
        }

        .col {
            padding-left: 30px;
            padding-right: 30px;

            @media (min-width: 1200px) {
                padding-left: 50px;
                padding-right: 50px;
            }
        }

        @each $prefix in $column-prefixes {
            @for $n from 1 to 13 {
                .#{$prefix}#{$n} {
                    padding-left: 30px;
                    padding-right: 30px;

                    @media (min-width: 1200px) {
                        padding-left: 50px;
                        padding-right: 50px;
                    }
                }
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}