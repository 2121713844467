@mixin icomoon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin dropdown {
    position: relative;

    &:after {
        @include icomoon;
        content: '\e910';
        position: absolute;
        top: 8px;
        right: 16px;
        font-size: 22px;
    }

    &[aria-expanded=true] {
        &:after {
            content: '\e911';
        }
    }
}

@mixin select($padding-right, $right) {
    background-image: url(/assets/images/icons/expand-more.svg);
    background-repeat: no-repeat;
    background-position: center right $right;
    padding-right: $padding-right;

    &:focus {
        background-image: url(/assets/images/icons/expand-less.svg);
    }
}