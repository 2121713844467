.card {
    border: none;
}

.info-card {
    background-color: $color-blue-20;
    border-radius: 3px;
    padding: 15px;
    height: 100%;

    &.disabled {
        background-color: $color-body;
    }

    @media (min-width: 992px) {
        padding: 25px;
    }

    &__title {
        color: $color-blue-100;
        font-size: 18px;
        font-weight: 700;
        margin: 0;
    }

    &__id {
        font-size: 16px;
    }

    &__info {
        margin: 15px 0;
        font-size: 16px;

        dt {
            font-weight: 700;
            margin-bottom: 4px;

            &+dt {
                margin-top: 10px;

            }
        }
    }

    &__link {
        transition: 0.15s all;
        color: $color-blue-80;
        position: relative;
        max-width: 475px;

        :after {
            @include icomoon;
            content: "\e910";
            font-size: 32px;
            position: absolute;
            top: 7px;
            right: 10px;
            transform: rotate(-90deg);

            @media (min-width: 992px) {
                top: 17px;
                right: 20px;
            }
        }

        &:hover,
        &:focus {
            background-color: $color-blue-80;
            color: $color-white;
        }
    }

    &__edit-password {
        span {
            display: block;
        }

        span.date {
            color: $color-main;
        }

        span.date b {
            color: $color-blue-80;
        }

        &:hover span.date,
        &:focus span.date,
        &:hover span.date b,
        &:focus span.date b {
            color: $color-white;
        }
    }

    &--button {
        appearance: none;
        border: none;
        width: 100%;
        color: $color-blue-80;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.15s all;

        i {
            margin-top: 15px;
            font-size: 45px;
        }

        &:hover,
        &:focus {
            background-color: $color-blue-80;
            color: $color-white;

            i {
                font-size: 45px;
                color: $color-green;
            }
        }

        &[disabled] {
            opacity: .5;
            pointer-events: none;
        }
    }
}

.contact-card {
    background-color: $color-blue-20;
    border-radius: 3px;
    padding: 15px;

    @media (min-width: 992px) {
        padding: 30px;
    }

    &__title {
        color: $color-blue-80;
        font-size: 24px;
        font-weight: 700;
        margin: 0;
    }

    &__info {
        margin-top: 20px;
        margin-bottom: 0;
        font-size: 16px;

        @media (min-width: 992px) {
            margin-top: 34px;
        }

        &>div {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            gap: 5px;

            @media (min-width: 992px) {
                flex-direction: row;
                gap: 15px;
            }

            &>div:first-child {
                // label
                font-weight: 700;
                width: 35%;
            }

            &>div:last-child {
                // value
                width: 65%;
                white-space: pre-wrap;
            }
        }
    }
}

.relation-card {
    display: flex;
    gap: 25px;
    background-color: transparentize($color-white, $amount: 0.9);
    padding: 20px 25px;
    margin-bottom: 4px;
    text-align: left;

    &__content {
        flex: 1;
    }

    label,
    .label {
        color: $color-white;
    }

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__info {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 14px;
            word-break: break-all;
        }
    }
}

.card-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 700;
    height: 100px;
    background: $color-white;
    color: $color-blue-80;
    margin-bottom: 20px;
    text-decoration: none;
    border-radius: 4px;
    padding: 20px;

    &:hover,
    &:focus {
        color: $color-white;
        background-color: $color-blue-80;
    }

    @media (min-width: 992px) {
        height: 150px;
        font-size: 18px;
    }

    @media (min-width: 1200px) {
        font-weight: 400;
        font-size: 22px;
    }

    &-column {
        flex-direction: column;
        gap: 20px;
    }
}

.aide-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    height: 100%;
    width: 100%;
    text-align: center;
    color: $color-main;
    background-color: $color-white;
    text-decoration: none;
    border-radius: 4px;
    padding: 20px;
    border: none;

    &__icon {
        max-height: 33px;
        max-width: 33px;
    }

    .btn {
        line-height: 1;
        transition: none;
        pointer-events: none;
    }

    &:hover,
    &:focus {
        color: $color-white;
        background-color: $color-blue-80;

        .btn {
            background-color: $color-blue-80;
        }
    }

    @media (min-width: 1200px) {
        font-size: 18px;
    }
}

.annonce-card {
    display: flex;
    gap: 8px;
    width: 100%;
    height: fit-content;
    min-height: 180px;
    margin-top: 10px;
    flex-wrap: nowrap;
    background-color: $color-white;

    @media (min-width: 768px) {
        flex-wrap: nowrap;
    }

    &__img {
        width: 30%;
        height: auto;
        max-height: 200px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__info {
        padding: 16px;
        flex-grow: 1;
        width: 70%;

        &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;

            .creation-date::after {
                content: '';
                padding-right: 10px;
                margin-right: 10px;
                border-right: 2px solid $color-grey;
            }

            .status {
                display: flex;
                white-space: normal;
                margin-top: 2px;

                &::before {
                    content: '';
                    height: 16px;
                    min-height: 16px;
                    width: 16px;
                    min-width: 16px;
                    margin-right: 10px;
                    background-color: $color-error;
                    border-radius: 50%;
                    display: inline-block;
                }

                &.active::before {
                    background-color: $color-green;
                }
            }
        }

        &-content {
            overflow: hidden;

            &>h3 {
                font-size: 1.5em;
                font-weight: 700;
            }

            &>div {
                max-height: 200px;
                max-width: 800px;
                white-space: normal;

                * {
                    font-size: revert;
                    font-weight: revert;
                    line-height: revert;
                }
            }

            h1 {
                line-height: 30px;
            }

            h2 {
                line-height: 23px;
            }
        }
    }
}