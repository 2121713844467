.todo {
    background: #000;
    color: #fff;
    padding: 15px;
    font-size: 14px;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    &__title {
        background-color: #fff;
        color: #000;
        padding: 4px 10px;
        font-size: 20px;
        font-weight: 700;
    }
}