// body tag used to override inline styling
body {
    .confirm-box {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;

        &__content {
            background: $color-blue-80;
            color: $color-white;
            font-size: 15px;
            line-height: 1.1;
            text-align: center;
            border: none;
            padding: 50px 30px;

            @media (min-width: 992px) {
                width: 400px;
                font-size: 18px;
            }
        }

        &__actions {
            padding-top: 30px;
            display: flex;
            flex-flow: row-reverse wrap;
            justify-content: center;
            gap: 15px;

            button {
                appearance: none;
                margin: 0 !important;
                font-size: 14px;
                padding: 7px 16px;
                border-width: 2px;
                border-style: solid;
                border-radius: 18px;
                text-decoration: none;
                background-color: transparent;
                color: $color-white;
                border-color: transparent;

                &:hover,
                &:focus-visible {
                    border-color: $color-white;
                }

                &:first-child {
                    background-color: $color-green;
                    border-color: $color-green;

                    &:hover,
                    &:focus-visible {
                        background-color: transparent;
                        color: $color-green;
                    }
                }
            }
        }

        &__overlay {
            background-color: transparentize($color-white, 0.8);
        }
    }
}