@import 'imports/variables';
@import 'imports/mixin';
@import 'imports/general';
@import 'imports/icomoon/style.css';
@import 'imports/utilities';
@import 'imports/header';
@import 'imports/footer';
@import 'imports/form';
@import 'imports/buttons';
@import 'imports/tooltip';
@import 'imports/modal';
@import 'imports/dropdown';
@import 'imports/entity-card';
@import 'imports/breadcrumb';
@import 'imports/table';
@import 'imports/pagination';
@import 'imports/select';
@import 'imports/card';
@import 'imports/accordion';
@import 'imports/panel';
@import 'imports/tabs';
@import 'imports/filters';
@import 'imports/todo';
@import 'imports/toast';
@import 'imports/audit-fields';
@import 'imports/confirm';
@import 'imports/loading-indicator';
@import 'imports/notification';
@import 'imports/toggle-switch';
@import 'imports/data-list';
@import 'imports/datepicker';
@import 'imports/table-dropdown';
@import 'imports/info-block';
@import 'imports/charts';
@import 'imports/groupe-favoris-actions';
@import 'imports/banners';

@import 'imports/pages/login';