.info-block {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    &__icon {
        color: $color-white;
        background-color: $color-green;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        width: 30px;
        line-height: 0;
        height: 30px;
        border-radius: 50%;
        margin-right: 15px;
    }
}