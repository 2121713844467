.entity-card {
    background: $color-white;
    color: $color-main;
    border-radius: 4px;
    border: 0;
    padding: 22px 25px;
    transition: .25s ease-in-out;
    height: 100%;

    &__title {
        font-size: 17px;
        margin: 0 0 17px;
        font-weight: 600;
        text-transform: uppercase;
    }

    &__info {
        list-style: none;
        padding: 0;

        li {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 6px;
        }
    }

    &__btn.btn {
        margin-left: auto;
        margin-top: auto;
        background-color: $color-blue-100;
        border-color: $color-blue-100;
    }

    &:hover,
    &:focus-within {
        // background-color: $color-grey;
        box-shadow: $shadow-md;

        .entity-card__btn {
            background-color: $color-blue-80;
            border-color: $color-blue-80;
        }
    }
}