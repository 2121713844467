.groupe-favoris-actions {
    position: relative;
    display: none;

    @media(min-width: 992px) {
        display: block;
    }

    &__content {
        position: absolute;
        top: 0;
        left: -40px;
        width: 30px;
        height: 70px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media
        (min-width: 992px) and (max-width: 1011px),
        (min-width: 1260px) and (max-width: 1279px),
        (min-width: 1630px) and (max-width: 1649px) {
            left: -30px;
        }
    }

    &__button {
        appearance: none;
        border: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $color-green;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover,
        &:focus-visible {
            background-color: $color-blue-60;
        }

        &::before {
            color: $color-white;
            font-size: 13px;
        }
    }

}