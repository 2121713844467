.Toastify {
    &__toast-container {
        min-width: 320px;
        width: auto;
        max-width: 100%;

        @media (min-width: 768px) {
            max-width: 500px;
        }
    }

    &__toast-body {
        flex-shrink: 1;
        max-width: calc(100% - 20px);
        font-size: 12px;

        @media (min-width: 768px) {
            font-size: 14px;
        }

        & > div:last-child {
            overflow-wrap: break-word;
            max-width: calc(100% - 30px);

            & > p:last-child {
                margin-bottom: 0;
            }
        }
    }
}