.notification {
    border: 0;
    border-radius: 3px;
    margin-bottom: 30px;
    padding: 30px 15px 15px;
    position: relative;

    @media (min-width: 992px) {
        padding: 15px 15px 15px 30px;
    }

    &__flex {
        display: flex;
        flex-direction: column;

        @media (min-width: 992px) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__content {
        margin-right: auto;
    }

    &__title {
        font-size: 16px;
        font-weight: 700;
        margin: 0 0 10px;

        @media (min-width: 992px) {
            font-size: 20px;
        }
    }

    &__text {
        font-size: 14px;
        margin: 0 0 10px;

        @media (min-width: 992px) {
            font-size: 16px;
        }
    }

    &__link {
        margin-left: auto;
        flex-shrink: 0;

        @media (min-width: 992px) {
            margin-left: 30px;
        }
    }

    &__close {
        appearance: none;
        border: 0;
        background: 0;
        color: inherit;
        font-size: 18px;
        margin-left: 30px;
        font-weight: 700;
        position: absolute;
        top: 5px;
        right: 5px;

        @media (min-width: 992px) {
            position: static;
        }
    }

    &-info {
        background-color: $color-blue-40;
        color: $color-main;

        .notification__text {
            color: $color-main;
        }
    }

    &-danger {
        background-color: $color-error;
        color: $color-white;

        .notification__text {
            color: $color-white;
        }
    }

    &-success {
        background-color: $color-green;
        color: $color-white;

        .notification__text {
            color: $color-white;
        }
    }
}

.notification-menu {
    position: fixed;
    top: 75px;
    right: 0;
    width: 100%;
    height: calc(100% - 75px);
    max-width: 380px;
    z-index: 400;
    background-color: $color-white;
    padding: 20px 15px 20px 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px #00000029;
    transition: 0.1s linear;
    transform: translateX(100%);

    &.open {
        transform: none;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    &__close {
        appearance: none;
        background-color: transparent;
        border: 0;
        color: $color-blue-80;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
    }

    &__title {
        color: $color-blue-80;
        font-weight: 700;
        font-size: 20px;
        margin: 0;
    }

    &__links {
        list-style: none;
        padding: 0;

        li {
            display: flex;
            align-items: flex-start;

            i {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                font-size: 16px;
                background-color: $color-blue-80;
                color: $color-white;
                border-radius: 50%;
                margin-right: 10px;
            }

            a {
                font-size: 14px;
                color: $color-main;
            }
        }
    }

    &__seemore {
        margin: 0 auto;
    }
}

.small-notification {
    overflow-y: auto;
    list-style: none;
    padding: 0 5px 0 0;
    font-family: $font-arial;
    padding-right: 5px;

    &__item {
        background-color: $color-body;
        padding: 15px 15px 15px 20px;
        margin: 0 0 10px;
    }

    &__top {
        justify-content: space-between;
        gap: 8px;
        margin-bottom: 5px;
        position: relative;
    }

    &__title {
        position: relative;
        font-size: 15px;
        font-weight: 700;
        color: $color-blue-80;
        margin: 0;
        max-width: 220px;
        word-wrap: break-word;

        &.unread::before {
            content: '';
            display: inline-block;
            position: absolute;
            left: -10px;
            top: 3px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $color-error;
        }
    }

    &__text {
        font-size: 12px;
        color: $color-main;
        margin: 0 0 10px;
        word-wrap: break-word;

        p {
            margin: 0;
        }
    }

    &__btn {
        border: none;
        font-size: 12px;
        color: $color-blue-80;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &__date {
        font-size: 12px;
        color: $color-blue-80;
        position: absolute;
        right: 0px;
        top: 0px;
    }
}