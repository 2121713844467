.toggle-switch {
    &__switch {
        position: relative;
        display: inline-block;
        width: 38px !important;
        height: 18px;
        margin: 0;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-white;
        border-radius: 34px;

        &:before {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            left: 4px;
            bottom: 3px;
            background-color: $color-green;
            transition: transform .1s linear;
            border-radius: 50%;
        }
    }

    &__switch input:checked + .toggle-switch__slider {
        &:before {
            transform: translateX(16px);
        }
    }

    &__switch input:focus + .toggle-switch__slider {
        outline: 1px solid $color-blue-60;
    }

    &__label {
        color: $color-main;
        font-size: 14px;
        font-weight: 700;
        opacity: 0.5;

        &._off {
            margin-right: 8px;
        }

        &._on {
            margin-left: 8px;
        }

        &._active {
            opacity: 1;
        }
    }

    &--blue {
        .toggle-switch {
            &__label {
                color: $color-main;
            }

            &__slider {
                background-color: $color-blue-80;

                &:before {
                    background-color: $color-error;
                }
            }

            &__switch input:checked + .toggle-switch__slider {
                &:before {
                    background-color: $color-green;
                }
            }
        }
    }

    &--white {
        .toggle-switch {
            &__label {
                color: $color-white;
            }

            &__slider {
                background-color: $color-white;

                &:before {
                    background-color: $color-error;
                }
            }

            &__switch input:checked + .toggle-switch__slider {
                &:before {
                    background-color: $color-green;
                }
            }
        }
    }

    &__wrp {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .toggle-switch {
            margin: 0 15px 6px 0;
        }
    }
}

.toggle-switch-all {
    position: relative;
    display: none;

    @media(min-width: 992px) {
        display: block;
    }

    &__content {
        position: absolute;
        top: 0;
        left: -40px;
        width: 30px;
        height: 70px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media
            (min-width: 992px) and (max-width: 1011px),
            (min-width: 1260px) and (max-width: 1279px),
            (min-width: 1630px) and (max-width: 1649px) {
            left: -30px;
        }
    }

    &__button {
        appearance: none;
        border: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $color-blue-80;
        position: relative;

        &:hover,
        &:focus-visible {
            background-color: $color-blue-60;
        }

        &:before {
            content: '';
            width: 20px;
            height: 10px;
            border-radius: 12px;
            background: $color-white;
            position: absolute;
            top: 10px;
            left: 5px;
        }

        &:after {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            top: 11px;
        }

        &:first-child:after {
            background-color: $color-green;
            right: 6px;
        }

        &:last-child:after {
            background-color: $color-error;
            left: 6px;
        }
    }
}