.table-responsive {
    margin-bottom: 30px;

    .align-items-right {
        align-items: end !important;
    }
}

.table-custom {
    font-size: 14px;
    table-layout: auto;

    &>thead {
        background-color: $color-blue-80;
        color: $color-white;

        tr {
            background-color: inherit;
        }

        th {
            padding: 6px 8px;
            border: none;
            font-weight: 400;
            vertical-align: middle;
            background-color: inherit;

            &.light {
                background-color: #258cbf;
            }

            &.transparent {
                background-color: $color-body !important;
            }

            &>div {
                background-color: inherit;
            }

            &:first-child {
                padding-left: 14px;
            }

            &:last-child {
                padding-right: 14px;
            }
        }
    }

    &>tbody {
        color: $color-main;
        text-align: left;

        &>tr {
            background-color: $color-white;

            &:nth-child(2n) {
                background-color: $color-blue-0;
            }

            &:not(:first-child) {
                td {
                    border-top: 1px solid $color-grey;
                }
            }

            &.dark {
                background-color: $color-blue-0;

                td.light {
                    background-color: $color-white;
                }

                &:nth-child(2n) {
                    background-color: $color-blue-20;

                    td.light {
                        background-color: $color-blue-0;
                    }
                }
            }

            &.blue-100 {
                background-color: $color-blue-100 !important;

                &>td {
                    color: $color-white;
                    box-shadow: inset 1px 0 0 transparentize($color-white, .7);
                }

                button.accordion-button {
                    color: $color-white;
                    background-color: unset;
                }
            }

            &.blue-80 {
                background-color: $color-blue-80 !important;

                &>td {
                    color: $color-white;
                    box-shadow: inset 1px 0 0 transparentize($color-white, .7);
                }

                button.accordion-button {
                    color: $color-white;
                    background-color: unset;
                }
            }

            &.blue-40 {
                background-color: $color-blue-40 !important;

                &>td {
                    color: $color-blue-80;
                    box-shadow:
                        inset 0 1px 0 transparentize($color-main, .9),
                        inset 1px 0 0 transparentize($color-main, .9);
                }

                button.accordion-button {
                    color: $color-blue-80;
                    background-color: unset;
                }
            }
        }

        td {
            border: none;
            padding: 6px 14px;
            vertical-align: middle;
            background-color: inherit;
            white-space: pre;

            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }

            &.cell-warning {
                background-color: $color-error !important;
                color: $color-white;
            }

            &.cell-line-wrap {
                white-space: unset;
            }

            &.cell-ellipsis {
                max-width: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &__action {
        text-align: center;
        width: 1%;
    }

    &.table-bordered {
        @media (min-width: 768px) {
            thead {
                th {
                    box-shadow: inset 1px 0 0 transparentize($color-white, .7);
                }

                th.no-box-shadow {
                    box-shadow: none;
                }
            }

            tbody {
                td {
                    box-shadow:
                        inset 0 1px 0 transparentize($color-main, .9),
                        inset 1px 0 0 transparentize($color-main, .9);
                }

                td.no-box-shadow {
                    box-shadow: none;
                }
            }
        }
    }
}

.table-thead-sticky {
    @media (min-width: 767px) {
        overflow-x: scroll;
        overflow-y: scroll;
        height: 500px;

        .table-responsive {
            overflow-x: unset;
        }

        thead {
            position: sticky;
            top: 0;
            z-index: 1;
        }

        th {
            position: sticky;
            top: 0;
            z-index: 1;
        }
    }
}

.table-card-mobile {
    @media (max-width: 767px) {
        overflow: unset;

        .table-custom {
            thead {
                display: none;
            }

            tbody {
                display: flex;
                flex-direction: column;

                tr {
                    position: relative;
                    flex-direction: column;
                    padding: 20px 42px 14px 48px;
                    background: $color-white;
                    border: 1px solid $color-grey;
                    display: flex;
                }

                td {
                    border: 0 !important;
                    white-space: unset;

                    @media (min-width: 500px) {
                        display: flex;
                    }
                }

                td[data-label] {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 14px;
                    line-height: 1.1;
                    word-break: break-all;
                    padding: 0;
                    padding-right: 10px;
                    margin-bottom: 10px;


                    &:before {
                        content: attr(data-label);
                        display: block;
                        width: 80px;
                        font-size: 10px;
                        line-height: 1.2;
                        font-weight: 700;
                        margin-right: 10px;
                        flex-shrink: 0;

                        @media (max-width: 370px) {
                            width: 100%;
                        }
                    }
                }

                .table-check {
                    display: none;
                }

                .table-icon {
                    position: absolute;
                    top: 14px;
                    left: 14px;
                    padding: 0;
                }

                .table-actions {
                    position: absolute;
                    top: 14px;
                    right: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .btn-icon i {
                        font-size: 18px;
                    }
                }
            }

            .table-nested,
            .table-nested-fix {
                padding: 0;
                border: 0;
                display: block;
                background-color: $color-body;

                .table-nested-wrap {
                    display: block !important;
                }

                .table-custom {
                    thead {
                        display: block;

                        .table-nested-header {
                            padding: 0;
                        }
                    }

                    tbody {
                        tr {
                            padding: 12px 42px 12px 48px;
                        }

                        td {
                            font-size: 12px;
                            padding: 0 12px;
                        }

                        .table-icon {
                            font-size: 16px;
                        }
                    }

                }
            }
        }
    }
}

.table-fixed {
    @media (min-width: 768px) {
        // table-layout: fixed;

        tr {
            position: relative;
        }

        th,
        td {
            width: min-content;
            white-space: pre;

            &.fixed {
                position: sticky;
                white-space: unset;
                z-index: 1;
            }

            &.table-actions {
                position: sticky;
                left: unset !important;
                right: 0;
            }

        }

        .table-input,
        .table-label,
        .table-select {
            width: 100%;
            min-width: max-content;
        }

        .table-label {
            width: auto;
            flex: 1;
        }
    }
}

.table-check {
    background-color: inherit;
    width: 36px;
    display: table-cell;
    text-align: center;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

td.table-check {
    color: $color-blue-100;
}

.table-icon {
    color: $color-blue-80;
    margin-right: 14px;

    .action-btn:not(.action-btn--collapse) {
        padding: 0 14px 0 0;
    }
}

.table-actions {
    width: 1% !important;
}

.table-input,
.table-label {
    width: 100%;
    margin: 0;
    padding: 0 10px;
}

.table-input,
.table-select {
    width: 100%;
    height: 26px;
    background-color: $color-blue-100;
    border: $color-blue-60;
    color: $color-white;
    display: inline-block;
    margin: 0;
    padding: 0 10px;

    &::placeholder {
        opacity: 0.8;
        color: $color-white;
    }

    & + button.clear-button-no-background {
        background-color: unset;
    }
}

.table-select {
    appearance: none;
    padding-right: 14px;
    background: $color-blue-100 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='4' viewBox='0 0 8 4'%3e%3cpolygon points='0,0 8,0 4,4' fill='%23FFFFFF' /%3e%3c/svg%3e") right 4px center/8px 4px no-repeat;

}

.table-select.pristine {
    color: transparentize($color-white, .2);
}

.table-label {
    display: flex;
    align-items: center;
    font-weight: 700;
}

.table-sort {
    width: 18px;
    height: 26px;
    margin: 0;
    display: block;
    background-color: transparent;
    border: 0;
    color: $color-white;
}

.table-card {
    margin-bottom: 30px;

    &-item {
        .table-card-wrap {
            padding: 20px 42px 14px 14px;
            background: $color-white;
            border: 1px solid $color-grey;
            display: flex;
            position: relative;
        }

        &:nth-child(2n) {
            .table-card-wrap {
                background: $color-blue-0;
            }
        }
    }


    &-dropdown {
        position: absolute;
        top: 14px;
        right: 14px;

        .btn-icon {
            i {
                font-size: 18px;
            }
        }
    }

    &-data {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        dt {
            width: 80px;
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: 10px;
            line-height: 1.2;

            @media (min-width: 576px) {
                width: 120px;
                font-size: 12px;
            }
        }

        dd {
            width: calc(100% - 90px);
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 1.1;
            word-break: break-all;

            @media (min-width: 576px) {
                width: calc(100% - 130px);
                font-size: 14px;
            }
        }
    }

    &-children {
        margin: 10px 0 20px 20px;
    }

    &-children-toggle {
        appearance: none;
        border: none;
        background: transparent;
        padding: 0;
        margin-right: 20px;
        height: 100%;
        font-size: 18px;
        color: $color-blue-80;
        position: relative;
        top: -4px;
        display: flex;
        flex-shrink: 0;

        &:after {
            @include icomoon;
            content: "\e910";
        }

        &[aria-expanded=true] {
            &:after {
                content: "\e911";
            }
        }
    }
}

.table-blue {
    thead {
        background-color: $color-blue-60;
        border-color: $color-blue-60;

        .table-input {
            background-color: transparentize($color-white, 0.8);
        }
    }
}

.table-nested {
    &-wrap {
        padding: 0 !important;
        background-color: $color-body !important;
        border: 0 !important;
    }

    &-header {
        th {
            background-color: $color-blue-60 !important;
        }
    }

    table {
        margin: 10px 0 10px 36px;
        width: calc(100% - 36px);

        tbody {
            td {
                box-sizing: border-box;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 300px;

                &.table-icon {
                    color: $color-blue-60;
                    width: 44px;
                }
            }

        }
    }
}

.table-nested-fix {
    visibility: hidden;
}

.table-accordion-mobile {
    @media (max-width: 767px) {
        overflow: unset;

        table,
        tbody {
            display: block;
        }
    }
}

@media (max-width: 767px) {
    .table-card-mobile .table-custom {
        tbody {
            .table-card {
                &__item {
                    padding: 12px 16px;
                    background-color: $color-white;

                    &:nth-child(odd) {
                        background-color: $color-blue-0;
                    }
                }

                &__line {
                    padding: 0;
                    margin-bottom: 10px;
                    align-items: baseline;

                    @media (min-width: 500px) {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }

                &__title {
                    font-size: 15px;
                    font-weight: 700;
                    margin-bottom: 15px;

                    &--clickable {
                        text-decoration: underline;
                    }
                }

                &__label {
                    width: 120px;
                    font-size: 10px;
                    line-height: 1.2;
                    font-weight: 700;
                    margin-right: 10px;
                    flex-shrink: 0;
                }

                &__actions {
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                &__icon {
                    position: absolute;
                    top: 10px;
                    left: 16px;
                }

                &__padding {
                    padding-left: 48px;
                }
            }
        }
    }
}

.table-accordion {
    display: block;
    background-color: $color-white !important;

    &:nth-child(odd) {
        background-color: $color-blue-0 !important;
    }

    &__accordion {
        background-color: inherit;
    }

    &__nonOpenable {

        @media (min-width: 768px) {
            display: none;
        }

        &>div {
            font-size: 15px;
            font-weight: 700;
            padding: 6px 0;
            overflow: hidden;
            white-space: pre-wrap;
        }
    }

    &__header {
        width: 100%;
        display: block;
        background-color: inherit;

        .accordion-button {
            position: relative;
            width: 100%;
            appearance: none;
            border: 0;
            font-size: 15px;
            font-weight: 700;
            text-align: left;
            color: $color-main;
            background-color: inherit;
            padding: 12px 1.75rem 12px 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            display: flex;
            align-items: center;

            &:after {
                @include icomoon;
                content: "\e910";
                font-size: 24px;
                position: absolute;
                right: 16px;
            }

            &[aria-expanded="true"] {
                background-color: $color-blue-40;

                &:after {
                    content: "\e911";
                }
            }
        }
    }

    .accordion-collapse {
        &.show {
            border-bottom: 10px solid $color-body;
        }
    }

    &__line {
        background-color: $color-white;
        padding: 9px 16px;
        font-size: 15px;
        display: flex;
        justify-content: space-between;

        &:nth-child(odd) {
            background-color: $color-blue-0;
        }

        &.line-warning {
            background-color: $color-error;
            color: $color-white;
        }
    }

    &__double {
        flex: 1;
        display: flex;
        flex-direction: column;

        &>span {
            display: flex;
            justify-content: flex-end;

            strong {
                margin-right: 10px
            }
        }
    }
}

.table-logo {
    width: 240px;
    height: 60px;
    padding: 0 !important;

    &__logo,
    &__label {
        width: 240px;
        height: 60px;
        object-fit: contain;
        object-position: center;
    }

    &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-white;
        font-weight: 700;
        font-size: 16px;
        padding: 10px;
        white-space: pre-wrap;
    }
}

.table-empty {
    text-align: center;
    font-size: 16px;
    color: $color-main;
    padding: 60px 0;

    @media (min-width: 767px) {
        font-size: 20px;
    }
}

.table-clickable-cell {
    cursor: pointer;
    background-color: transparentize($color-blue-100, 1);

    &:hover {
        background-color: transparentize($color-blue-100, .9);
    }
}

.table-seemore {
    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;
    line-height: 1;
    margin: 6px 0 12px;
    font-size: 14px;
    color: $color-blue-80;
    font-weight: 700;
    float: right;
    text-decoration: underline;

    @media (min-width: 767px) {
        font-size: 16px;
    }
}

.table-sortable {
    &__handle {
        color: $color-blue-100;
        cursor: move;
        font-size: 20px;
        margin-right: 5px;

        &.disabled {
            opacity: 0;
            cursor: unset;
            pointer-events: none;
        }
    }

    &__handle-wrp {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: $color-blue-100;

        &--card {
            width: 100%;
            flex-shrink: 0;
            break-after: always;
            margin-bottom: 5px;
        }
    }

    &__ghost {
        opacity: 0 !important;
    }

    &__chosen {
        opacity: .8;
    }

    &__fallback {
        display: table-row;

        table-layout: auto;
        opacity: 1 !important;
    }
}

.table-th-small {
    .table-input,
    .table-select,
    .table-label {
        width: 80px;
        text-overflow: ellipsis;
    }
}

.table-min-content {
    width: 1%;
    white-space: nowrap;
}