.filter-dropdown {
    margin-bottom: 6px;

    &__export {
        .btn-icon:after {
            content: none;
        }

        .dropdown-menu {
            min-width: max-content;
            width: 100%;
            text-align: center;
        }
    }

    &__header {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-blue-80;

    }

    &__toggle {
        appearance: none;
        color: $color-white;
        border: 0;
        background: transparent;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 15px;
        flex: 1;

        &::before {
            @include icomoon;
            content: '\e910';
            width: 20px;
            height: 20px;
            font-size: 20px;
            text-align: center;
            line-height: 20px;
            border-radius: 50%;
            background-color: $color-green;
            margin-right: 10px;
        }

        &.open {
            &::before {
                content: '\e911';
            }
        }
    }

    &__content {
        background-color: $color-blue-20;
        padding: 20px 15px;

        @media (min-width: 992px) {
            padding: 20px 35px;
        }

        &__title {
            font-size: 18px;
            font-weight: 700;
            color: $color-blue-80;
        }

        .form-group {
            margin-bottom: 16px;
            
            .input-group {
                font-size: 14px;

                &:after {
                    height: 1px;
                }

                .form-control {
                    height: 32px;
                    font-size: 14px;
                }
            }

            &--inline {
                label,
                .label {
                    font-weight: 400;

                    @media (min-width: 992px) {
                        width: 100px;
                    }
                }
            }
        }
    }

    &__footer {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 14px;

        @media (min-width: 500px) {
            justify-content: flex-end;
        }

        .btn {
            line-height: 1;
        }
    }

    &__facets {
        background-color: $color-blue-80;
        padding: 10px 10px 14px;
        border-top: 1px solid transparentize($color-white, 0.8);
        overflow: auto;
    }
}

.facet {
    background-color: $color-green;
    color: $color-white;
    padding: 5px;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    white-space: nowrap;
    margin-right: 4px;

    &__close {
        appearance: none;
        background: transparent;
        border: 0;
        color: inherit;
        padding: 0;
        font-size: 15px;
        line-height: 15px;
        position: relative;
        top: 3px;
    }
}

.filter-save {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;

    @media (min-width: 991px) {
        flex-direction: row;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        color: $color-blue-80;
        margin: 6px 20px 0 0;
        flex-shrink: 0;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding-bottom: 10px;
    }

    &__item {
        border-radius: 3px;
        background-color: $color-blue-80;
        color: $color-white;
        display: flex;
        align-items: center;

        .btn-icon {
            color: $color-white;
            width: 20px;
            height: 20px;
            font-size: 14px;
            z-index: 2;
            margin: 4px 10px 4px 0;

            i {
                font-size: inherit;
            }

            &:hover {
                background-color: $color-blue-60;
            }

            @media (min-width: 992px) {
                width: 26px;
                height: 26px;
                font-size: 16px;
                margin: 4px 10px 4px 0;
            }
        }
    }

    &__name {
        text-transform: capitalize;
        width: 80px;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        padding: 4px 0 4px 10px;

        @media (min-width: 992px) {
            width: 90px;
            font-size: 14px;
        }
    }
}

.criteria {
    margin: 20px 0;
    padding: 26px 34px;
    background-color: transparentize($color-blue-80, $amount: 0.9);

    &__toggle {
        appearance: none;
        color: $color-blue-80;
        font-weight: 700;
        text-decoration: underline;
        background-color: transparent;
        border: 0;
        font-size: 16px;
        margin: 10px 0;
    }

    &__title {
        color: $color-blue-80;
        font-size: 18px;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        i {
            margin-left: 10px;
        }
    }

    &__content {
        margin: 30px 0;
    }

    &__actions {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding-bottom: 15px;
        height: 100%;
    }

    &__add,
    &__remove {
        appearance: none;
        background-color: transparent;
        border: 0;
        color: $color-blue-100;
        height: 32px;
        width: 32px;
        font-size: 18px;
        margin: 2px;
        border-radius: 50%;

        &[disabled] {
            opacity: 0.5;
        }
    }

    &__add {
        background-color: $color-blue-100;
        color: $color-white;
    }
}