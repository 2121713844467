.react-tooltip {
    background-color: $color-blue-80 !important;
    color: $color-white !important;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    padding: 7px 9px !important;
    max-width: 200px;
    z-index: 99999 !important;

    &.show {
        opacity: 1 !important;
    }

    &.place-top:after {
        border-top-color: $color-blue-80 !important;
    }

    &.place-right:after {
        border-right-color: $color-blue-80 !important;
    }

    &.place-bottom:after {
        border-bottom-color: $color-blue-80 !important;
    }

    &.place-left:after {
        border-left-color: $color-blue-80 !important;
    }

    &.type-success {
        background-color: $color-green !important;
        color: $color-white !important;

        &.place-top:after {
            border-top-color: $color-green !important;
        }

        &.place-right:after {
            border-right-color: $color-green !important;
        }

        &.place-bottom:after {
            border-bottom-color: $color-green !important;
        }

        &.place-left:after {
            border-left-color: $color-green !important;
        }
    }

    &.type-info {
        background-color: $color-blue-60 !important;
        color: $color-white !important;

        &.place-top:after {
            border-top-color: $color-blue-60 !important;
        }

        &.place-right:after {
            border-right-color: $color-blue-60 !important;
        }

        &.place-bottom:after {
            border-bottom-color: $color-blue-60 !important;
        }

        &.place-left:after {
            border-left-color: $color-blue-60 !important;
        }
    }
}
