.audit-fields {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0 0 30px;

    i {
        flex-shrink: 0;
        color: $color-blue-80;
        margin-right: 20px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 12px;
        font-style: italic;

        @media (min-width: 992px) {
            font-size: 14px;
        }
    }
}