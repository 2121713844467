.data-list {
    font-size: 14px;
    margin-bottom: 30px;

    @media (min-width: 500px) {
        display: flex;
        flex-flow: row wrap; 
    }

    @media (min-width: 768px) {
        font-size: 16px;
    }

    dt {
        font-weight: 700;

        @media (min-width: 500px) {
            width: 35%;
            display: flex;
            margin-bottom: 15px;

            &:after {
                content: '';
                flex: 1;
                height: 100%;
                border-bottom: 1px dashed;
                height: 16px;
                margin: 0 10px;
            }
        }

    }

    dd {
        @media (min-width: 500px) {
            width: 65%;
            margin-bottom: 15px;
        }
    }
}