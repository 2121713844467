.info-banner {
    background-color: $color-blue-100;
    color: $color-white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 30px 12px;
    border-bottom: 3px solid $color-white;
    position: sticky;
    top: 0;
    z-index: 1;
}

.annonce-banner {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-top: 20px;
    padding: 16px 32px;
    background-color: $color-blue-40;

    @media (max-width: 991px) {
        flex-direction: column;
        gap: 10px;
    }

    &__info {
        flex-grow: 1;

        h2 {
            color: $color-blue-80;
            font-size: 20px;
            font-weight: 700;
        }

        p {
            margin-bottom: 5px;
        }
    }

    &__action {
        button {
            width: fit-content;
            height: fit-content;
            white-space: nowrap;
        }
    }
}
